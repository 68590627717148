.PortfolioHero-wrapper {
    background-color: #1C1C1C;
    color: white;
    height: 90vh;
  }


  .PortfolioHero-label {
    color: #808080;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
  }

  .PortfolioHero-title {
    font-size: 5rem;
    line-height: 1.1;
  }

  .PortfolioHero-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: opacity 0.3s ease;
  }

  .PortfolioHero-link:hover {
    opacity: 0.8;
  }

  .PortfolioHero-arrow {
    font-size: 1.25rem;
  }

  .PortfolioHero-image-wrapper {
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* min-height: 600px; */
  }

  @media (max-width: 991px) {
    .PortfolioHero-title {
      font-size: 3.5rem;
    }
  }
  .PortfolioSolutions-accordion .accordion-button {
    background: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }

  .PortfolioSolutions-accordion .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .PortfolioSolutions-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }

  .PortfolioSolutions-accordion .accordion-body {
    padding: 1.5rem 0;
  }

  .PortfolioSolutions-accordion .accordion-button::after {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem;
  }


  .PortfolioCarousel-title {
    font-weight: 700;
  }
/* 
  .PortfolioCarousel-image-wrapper {
    height: 300px;
    overflow: hidden;
  } */

  .PortfolioCarousel-badge {
    /* color: white; */
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .PortfolioCarousel-date {
    color: #6c757d;
    font-size: 14px;
  }

  .PortfolioCarousel-description {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .PortfolioCarousel-button {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 50%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .PortfolioCarousel-button:hover {
    background-color: #e9ecef;
  }

  .PortfolioCarousel-wrapper {
    margin: 0 -1rem;
  }

  .PortfolioCarousel-item {
    height: 100%;
  }

  .SuccessStories-card {
    transition: transform 0.3s ease;
  }

  /* .SuccessStories-card:hover {
    transform: translateY(-8px);
  } */

  .SuccessStories-card-overlay {
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .SuccessStories-arrow {
    transition: transform 0.3s ease;
}

/* .SuccessStories-card:hover .SuccessStories-arrow {
    transform: translateX(4px);
    } */
    
    .SuccessStories-image-wrapper {
        border-radius: 12px;
        overflow: hidden;
    }
    .SuccessStories-image:hover{
        transform: scale(1.05);
        transition: transform 1s ease;
    }
    
    .SuccessStories-title {
        font-weight: 700;
    }
    
    .SuccessStories-metadata small {
        font-size: 0.75rem;
        letter-spacing: 0.05em;
    }

    .PortfolioSolutions-accordion-list{
      list-style: none;
    }