/* .allServiceCard {
  width: 100%;
  height: 20rem;
  background-color: rgb(239, 240, 242);
  border-radius: 10px;
}

.allServiceCard:hover {
  background-image: url("../../Images/card.png");
  .CardFooter{
    color: white !important;
  }
  .card-header{
    color: white !important;
  }
}

.card-header {
  padding-left: 30px !important;
}

.card-body {
  margin-bottom: 20px !important;
  padding-left: 30px !important;
  padding-bottom: 20px;
}

.CardFooter {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
} */


.allServiceCard {
  width: 100%;
  height: 20rem;
  background-color: rgb(239, 240, 242);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.allServiceCard::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../Images/card.png");
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.allServiceCard:hover::before {
  opacity: 1;
}

.allServiceCard:hover {
  color: white;
}

.allServiceCard .card-header,
.allServiceCard .CardFooter {
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}

.allServiceCard:hover .card-header,
.allServiceCard:hover .CardFooter {
  color: white;
}

.card-header {
  padding-left: 20px;
}

.card-body {
  margin-bottom: 0 !important;
  padding-left: 20px;
  padding-bottom: 20px;
}

.arrow-container {
  display: flex;
  align-items: center;
}

.arrow-hovered {
  transform: translateX(5px);
  transition: transform 0.3s ease;
}

.CardFooter {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
} 