/* .ulbig{
    padding-left: 18px;
}


.biggercardbutton123 {
   
   font-family: "Inter" !important;
    background-color: white !important;
    border-radius: 30px !important;
    color: black !important;
    border: 2px solid #EC981F !important ;
    font-weight: 600 !important;
}
.biggercardbuttonbtncontainer{
    display: flex;
    justify-content: space-between;
}

@media (max-width:500px){

  
}
 */



 .ulbig {
     padding-left: 18px;
 }

 .biggercardbutton123 {
     font-family: "Inter" !important;
     background-color: white !important;
     border-radius: 30px !important;
     color: black !important;
     border: 2px solid #ec981f !important;
     font-weight: 600 !important;
 }

 .biggercardbuttonbtncontainer {
     display: flex;
     justify-content: space-between;
 }