.happy-client{
    font-family: Inter;
font-size: 46px;
font-weight: 600;
line-height: 72px;
}
.molex{
   height: 80px;
   width: 265px;
}
.autocard-molex{
   height: 110px;
   width: 265px;
}
.molex23 {
    height: 118px;
    width: 260px;
}
.molex-div{
    margin-left: 6rem;
    margin-right: 3.5rem;
}
.molex234 {
    height: 147px;
    width: 240px;
}
.milestone-1234{
font-family: "Inter";
font-size: 17px;
font-weight: 500;
line-height: 35px;
text-align: justify;
margin-top: 1.2rem !important;
margin-bottom: 1.7rem !important;
}

@media (max-width:500px) {
    .milestone-1234{
        font-family: "Inter";
        padding: 5% !important;

    }
}


