.ContactUs-wrapper {
    min-height: 100vh;
}

.ContactUs-heading {
    font-size: 49px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .ContactUs-heading {
        font-size: 20px;
    }
}

.ContactUs-job-details {
    font-size: 14px;
    color: #676c6d;
}

.ContactUs-title {
    font-weight: 500;
    font-size: 66px;
}

@media (max-width: 767px) {
    .ContactUs-title {
        font-size: 27px;
    }
}

.ContactUss2-title {
    font-weight: 500;
    font-size: 33px;
}

@media (max-width: 767px) {
    .ContactUss2-title {
        font-size: 23px;
    }
}

.ContactUssdiscription2-title {
    font-weight: 500;
    font-size: 28px;
    color: #676c6d;
}

@media (max-width: 767px) {
    .ContactUssdiscription2-title {
        font-size: 23px;
    }
}



.ContactUsForm-input, .ContactUsForm-select .react-select__control {
    background-color: transparent !important; 
    border: none !important; 
    border-bottom: 1px solid #ccc !important;
    box-shadow: none;
    border-radius: 0;
    padding: 20px 0px;
    color: white !important;
}

.ContactUsForm-input:focus, .ContactUsForm-select .react-select__control--is-focused {
    background-color: transparent !important; 
    border-bottom: 1px solid #007bff !important; 
    box-shadow: none !important;
    outline: none !important;
}

.ContactUsForm-input::placeholder, .ContactUsForm-select .react-select__placeholder {
    color: rgb(143, 139, 139) !important;
    font-size: 16px !important;
    text-align: left !important;
}

.ContactUsForm-select .react-select__menu {
    background-color: #333;
    border-radius: 0.25rem;
    color: white;
}

.ContactUsForm-select .react-select__option {
    background-color: #333;
    color: white;
    border: 1px solid #fff;
}

.ContactUsForm-select .react-select__option--is-selected {
    background-color: #555;
}

.ContactUsForm-select .react-select__option:hover {
    background-color: #444;
}

/* display grid to show three cards and responsiveness accoingly */
.ContactUs-card-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px;
}

@media (max-width: 767px) {
    .ContactUs-card-container {
        grid-template-columns: 1fr;
    }
}

.contactusCard1 {
    background-color: #390438;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
    border: 1PX solid #313234;
}

.contactusCard1:hover { 
    background-color: #39043882;
}
.contactusCard2 {
    background-color: #141E27;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
    border: 1PX solid #313234;
}

.contactusCard2:hover { 
    background-color: #141e2793;
}