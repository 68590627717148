.CareersSectionTwo-wrapper {
    min-height: 100vh;
}

.CareersSectionTwo-heading {
    font-size: 49px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .CareersSectionTwo-heading {
        font-size: 20px;
    }
}

.CareersSectionTwo-job-details {
    font-size: 14px;
    color: #676c6d;
}

.CareersSectionTwo-job-title {
    font-weight: 500;
    font-size: 24px;
}

.CareersSectionTwo-pagination-list .page-item .page-link {
    background-color: transparent;
    border-color: #22292D;
    color: #fff;
}

.CareersSectionTwo-pagination-list .page-item.active .page-link {
    background-color: #fff;
    color: #000;
    border-color: #22292D;
}

.CareersSectionTwo-apply-btn {
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 25px;
    cursor: pointer;
    background-color: #42484B;
    transition: background-color 0.3s ease;
}

.CareersSectionTwo-apply-btn:hover {
    background-color: #4A5050;
    color: white;
}
.CareersSectionTwo-pagination-prev-button{
    border: none;
    color: white;
    background-color: #42484B;
    border-radius: 4px;
    padding: 8px 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.CareersSectionTwo-pagination-prev-button:hover{
    background-color: #4A5050;
    color: white;
}

.CareersSectionTwo-pagination-next-button{
    border: none;
    color: rgb(0, 0, 0);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 8px 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.CareersSectionTwo-pagination-next-button:hover{
    background-color: #9ea3a3;
    color: rgb(0, 0, 0);
}