.StoriesHeroSec-wrapper {
    background-color: #1C1C1C;
    color: white;
    height: 90vh;
  }


  .StoriesHeroSec-label {
    color: #808080;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
  }

  .StoriesHeroSec-title {
    font-size: 5rem;
    line-height: 1.1;
  }

  .StoriesHeroSec-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: opacity 0.3s ease;
  }

  .StoriesHeroSec-link:hover {
    opacity: 0.8;
  }

  .StoriesHeroSec-arrow {
    font-size: 1.25rem;
  }

  .StoriesHeroSec-image-wrapper {
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* min-height: 600px; */
  }

  @media (max-width: 991px) {
    .StoriesHeroSec-title {
      font-size: 3.5rem;
    }
  }
  
  .casestudy-intro-title,
  .casestudy-challenge-title,
  .casestudy-solution-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .casestudy-intro-text,
  .casestudy-challenge-text,
  .casestudy-solution-text {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }

  /* Challenge Section */
  .casestudy-challenge {
    margin: 2rem 0;
  }
  
  /* Solution Section */
  .casestudy-solution {
    margin: 2rem 0;
  }
