.CompanySectiononeheading {
    font-size: 61px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}

@media (max-width: 767px) {
    .CompanySectiononeheading {
        font-size: 27px;
        font-weight: 500;
    }
}

.CompanySectiononetext {
    font-size: 16px;
    font-weight: 400;
    color: rgb(158, 166, 167);
}

.CompanySectiononebutton {
    background-color: #0066ff;
    border: none;
    padding: 10px 30px;
    font-size: 1.1rem;
}

.CompanySectiononeimg {
    width: 100%;
    object-fit: cover;
}

.CompanySectiononeimg-section {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0 !important;
}

.CompanySectiononecontent-section2>* {
    margin: 16px 0;
}


.vestian-text {
    padding-right: 30px;
}

.vestian-headline {
    font-size: 3rem;
    font-weight: 500;
    color: #0a151e;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .vestian-headline {
        font-size: 1.6rem;
    }
}

.vestian-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #9ea6a7;
}

.vestian-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vestian-team-image {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    max-width: 100%;
}

.VestianSection-border>div>.border-top {
    border-color: #162129 !important;
}

.company-section-one-img>div {
    position: relative;
}

@media (min-width: 768px) {
    .company-section-one-img>div {
        position: absolute;
        left: -8%;
        aspect-ratio: 1 / 1;
    }
}

.PortfolioHero-wrapper {
    background-color: #1C1C1C;
    color: white;
    height: 90vh;
  }

  .PortfolioHero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    background-image: url('https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665ddb0df29ed73dfe1f6759_branded-svg_light.svg');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    pointer-events: none;
  }

  .PortfolioHero-label {
    color: #808080;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
  }

  .PortfolioHero-title {
    font-size: 5rem;
    line-height: 1.1;
  }

  .PortfolioHero-link {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: opacity 0.3s ease;
  }

  .PortfolioHero-link:hover {
    opacity: 0.8;
  }

  .PortfolioHero-arrow {
    font-size: 1.25rem;
  }

  .PortfolioHero-image-wrapper {
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* min-height: 600px; */
  }

  @media (max-width: 991px) {
    .PortfolioHero-title {
      font-size: 3.5rem;
    }
  }
  .Award-accordion .accordion-button {
    background: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }

  .Award-accordion .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .Award-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }

  .Award-accordion .accordion-body {
    padding: 1.5rem 0;
  }

  .Award-accordion .accordion-button::after {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem;
  }


  .Award-title {
    font-weight: 500;
    font-size: 49px;
    line-height: 59px;
  }
  @media (max-width: 767px) {
    .Award-title {
      font-size: 26px;
      line-height: 33px;
    }
  }

  .Award-image-wrapper {
    height: 300px;
    aspect-ratio: 3/2;
    /* padding: 10px; */
  }

  .Award-badge {
    /* color: white; */
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .Award-date {
    color: #6c757d;
    font-size: 14px;
  }

  .Award-description {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .Award-button {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 50%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .Award-button:hover {
    background-color: #e9ecef;
  }

  .Award-wrapper {
    margin: 0 -1rem;
  }

  .Award-item {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
  }
  .Award-accordion .accordion-button {
    background: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }

  .Award-accordion .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .Award-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }

  .Award-accordion .accordion-body {
    padding: 1.5rem 0;
  }

  .Award-accordion .accordion-button::after {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem;
  }

  .GlobalLeaderShip-accordion .accordion-button {
    background: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }

  .GlobalLeaderShip-accordion .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .GlobalLeaderShip-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }

  .GlobalLeaderShip-accordion .accordion-body {
    padding: 1.5rem 0;
  }

  .GlobalLeaderShip-accordion .accordion-button::after {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem;
  }


  .GlobalLeaderShip-title {
    font-weight: 500;
    font-size: 49px;
    line-height: 59px;
  }
  @media (max-width: 767px) {
    .GlobalLeaderShip-title {
      font-size: 26px;
      line-height: 33px;
    }
  }

  .GlobalLeaderShip-image-wrapper {
    height: 300px;
    aspect-ratio: 3/2;
    /* padding: 10px; */
  }

  .GlobalLeaderShip-badge {
    /* color: white; */
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }

  .GlobalLeaderShip-date {
    color: #6c757d;
    font-size: 14px;
  }

  .GlobalLeaderShip-description {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .GlobalLeaderShip-button {
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 50%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .GlobalLeaderShip-button:hover {
    background-color: #e9ecef;
  }

  .GlobalLeaderShip-wrapper {
    margin: 0 -1rem;
  }

  .GlobalLeaderShip-item {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
  }
  .GlobalLeaderShip-accordion .accordion-button {
    background: none;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1.5rem 0;
    border-bottom: 1px solid #dee2e6;
  }

  .GlobalLeaderShip-accordion .accordion-button:not(.collapsed) {
    background: none;
    box-shadow: none;
  }

  .GlobalLeaderShip-accordion .accordion-button:focus {
    box-shadow: none;
    border-color: #dee2e6;
  }

  .GlobalLeaderShip-accordion .accordion-body {
    padding: 1.5rem 0;
  }

  .GlobalLeaderShip-accordion .accordion-button::after {
    width: 1.5rem;
    height: 1.5rem;
    background-size: 1.5rem;
  }

  .PortfolioCarousel-title {
    font-weight: 500;
    font-size: 49px;
    line-height: 59px;
  }
  @media (max-width: 767px) {
    .PortfolioCarousel-title {
      font-size: 26px;
      line-height: 33px;
    }
  }

  