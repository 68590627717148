/* Image Section */
.image-wrapper img {
    aspect-ratio: 5 / 6;
    object-fit: cover;
    width: 100%;
    border-radius: 0.75rem;
}

/* Text Section */
.text-wrapper .title {
    font-weight: 600;
    line-height: 1.1;
}

.text-wrapper .description {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
}

.AllServicesBoxComponentservice-link {
    color: #000 !important;
    font-size: 1.1rem;
    font-weight: 500;
    display: inline-block;
    padding: 0.5rem;
    border-top: 1px solid #eee;
    text-align: center;
    transition: color 0.3s ease;
}

.AllServicesBoxComponentservice-link:hover {
    color: #666;
}

.cta {
    padding: 0.75rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.cta:hover {
    >.icon {
       transform: translateX(4px);
       transition: all 0.3s ease;
    }

    background-color: #204DEC !important;
}

/* Responsive Styles */
@media (max-width: 991px) {
    /* .image-wrapper {
      height: 50vh;
    } */

    .content-wrapper {
        padding: 1.5rem;
    }
}