/* .SecondeSectionButton {
    background-color: white;
    border-radius: 30px;
    border-color: #EC981F;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width:400px) {
    .defectSmallCardButton{
        margin-top: 60px !important;
    }
        .SecondeSectionButton {
            background-color: white;
            border-radius: 30px;
            border-color: #EC981F;
            padding-top: -5px !important;
        }
}
@media (min-width:370px) and (max-width:390px) {
    .SecondeSectionButton{  
        padding: 5px !important;
    } 
}
@media (min-width:370px) and (max-width:390px) {
    .SecondeSectionButton{  
        padding: 5px !important;
    } 
}
@media (max-width:370px) {
    .SecondeSectionButton{  
        padding: 0px !important;
    } 
}

 */

 .SecondeSectionButton {
     background-color: white;
     border-radius: 30px;
     border: 2px solid #ec981f;
     font-weight: 600 !important;
     padding: 6px 12px;
     font-family: "Inter" !important;
     color: black;
 }

 @media (max-width: 400px) {

     /* .defectSmallCardButton {
    margin-top: 60px !important;
  } */
     .SecondeSectionButton {
         padding-top: -5px !important;
         font-size: 600;
     }
 }