.footerimage {
  width: 100%;
  object-fit: cover;
  height: 75%;

}

.footerfirsttext {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.firstfootertext {
  color: white;
  font-family: "Roboto";
  font-size: 50px;
}

.footersecontextt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.secondfootertext {
  font-family: "Roboto";
  font-size: 50px;
}

.footerfirsttext{
    min-height: 100px;
}
.upperfooterContainer {
  padding: 0 !important;
  width: 100% !important;
}

.upperfooterImage {
  padding: 0 !important;
}

/* @media (min-width: 501px) and (max-width: 900px) {
  .footersecontextt{
    margin-top: -4% !important;
  }
} */

@media (max-width: 500px) {
  /* .footersecontextt{
    margin-top: -4% !important;
  } */

  .firstfootertext {
    font-size: 40px;
  }

  .secondfootertext {
    font-size: 40px;
  }

  .footerimage {
    height: auto;
  }
}