.naaaavdropdownicon {
  width: 20px !important;
  height: 20px !important;
  aspect-ratio: 1/1;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.naaaavdropdownicon.hovered {
  background-color: black;
}

.naaaavdropdownicon .arrow {
  position: absolute;
  transition: transform 0.3s ease;
}

.naaaavdropdownicon .arrow-white {
  transform: translateY(-130%);
}

.naaaavdropdownicon .arrow-black {
  transform: translateY(0);
}

.naaaavdropdownicon.hovered .arrow-white {
  transform: translateY(0);
}

.naaaavdropdownicon.hovered .arrow-black {
  transform: translateY(100%);
}

.naaaavdropdown {
  position: relative;
}

.naaaavdropdown:hover .naaaavdropdownicon {
  background-color: black;
}

.naaaavdropdown:hover .arrow-white {
  transform: translateY(0);
}

.naaaavdropdown:hover .arrow-black {
  transform: translateY(100%);
}
