.arrow-container {
  width: 23px !important;
  height: 20px;
  background-color: rgba(128, 128, 128, 0.5);
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.arrow-container.arrow-hovered {
  background-color: black;
}

.arrow-container .arrow {
  position: absolute;
  transition: transform 0.3s ease;
}

.arrow-container .arrow-white-mod {
  transform: translateX(-140%);
}

.arrow-container .arrow-black-mod {
  transform: translateX(0);
}

.arrow-container.arrow-hovered .arrow-white-mod {
  transform: translateX(0);
}

.arrow-container.arrow-hovered .arrow-black-mod {
  transform: translateX(100%);
}

.arrow-dropdown {
  position: relative;
}

.arrow-dropdown:hover .arrow-container {
  background-color: black;
}

.arrow-dropdown:hover .arrow-white-mod {
  transform: translateY(0);
}

.arrow-dropdown:hover .arrow-black-mod {
  transform: translateX(100%);
}
