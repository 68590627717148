.AllServices-heading{
    text-align: left;
    font-size: 161px;
    font-weight: 500;
    line-height: 161px;
    letter-spacing: -2px;
    color: rgb(10, 21, 30);
}
/* 
add responsive to the upper text, for mobile screen add small  text size and proper mediaqueery for responsive */
@media (max-width: 767px) {
    .AllServices-heading{
        text-align: left;
        font-size: 90px;
        font-weight: 500;
        line-height: 100px;
        letter-spacing: -2px;
        color: rgb(10, 21, 30);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .AllServices-heading{
        text-align: left;
        font-size: 100px;
        font-weight: 500;
        line-height: 100px;
        letter-spacing: -2px;
        color: rgb(10, 21, 30);
    }
}