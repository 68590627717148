.MainnNavBarImage {
  width: 100% !important;
  max-width: 80px !important;
  height: auto !important;
}

.NavBarContainer {
  padding: 0;
}

.mainnnContainerNavComponent {
  width: 100% !important;
  display: flex !important;
  /* justify-content: space-between !important; */
  transition: top 0.5s;
  background-color: #d5b890;
  z-index: 999 !important;
}

.mainnavbarhbsbuttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* md screen media query */
@media (max-width: 768px) {
  .mainnavbarhbsbuttoncontainer{
    width: 100% !important;
    justify-content: start !important;
    >button{
      margin: 0 !important;
      margin-left: 20px !important;
      padding-left: 1px;
      margin-right: auto !important;
    }
  }
}
@media (min-width: 600px) {
  .mainnnContainerNavComponent {
    position: fixed !important;
  }
}

.mainnnContainerNavComponent.show {
  top: 0px;
}

.mainnnContainerNavComponent.hide {
  top: -110px;
}

.navbar-toggler {
  margin-left: auto;
}

.mainnavBarButton {
  border: 3px solid #ec981f !important;
  padding: 8px !important;
  border-radius: 23px !important;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: #0c3f78 !important;
  white-space: nowrap !important;
}
@media (max-width: 830px) {
  .mainnavBarButton {
    font-size: 12px !important;
    padding: 6px !important;
  }
}

.MainnNavBarButtons {
  display: flex !important;
  justify-content: center !important;
  flex-grow: 1 !important;
  font-family: "Inter" !important;
  font-weight: 700 !important;
  gap: 2rem !important;
}

.MainnNavBarButtons .nav-link {
  white-space: nowrap !important;
  padding: 0.5rem 0.75rem !important;
  position: relative;
}

.MainnNavBarButtons .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 100%;
  background-color: #0c3f78;
  transition: width 0.3s ease;
}

@media (max-width: 576px) {
  .MainnNavBarImage {
    min-width: 60px !important;
  }

  .MainnNavBarButtons {
    gap: 0.5rem !important;
  }

  .MainnNavBarButtons .nav-link {
    padding: 0.25rem 0.5rem !important;
  }
}

@media (max-width: 768px) {
  .MainnNavBarButtons {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    justify-content: flex-start !important;
  }
}

.MainnNavBarImage {
  width: 100% !important;
  height: auto !important;
  min-width: 80px !important;
}

.NavBarContainer {
  padding: 0;
}

.mainnnContainerNavComponent {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  transition: top 0.5s, background-color 0.3s, backdrop-filter 0.3s;
  background-color: white;
  /* Semi-transparent background */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999 !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (min-width: 600px) {
  .mainnnContainerNavComponent {
    position: fixed !important;
  }
}

.mainnnContainerNavComponent.show {
  top: 0px;
}

.mainnnContainerNavComponent.hide {
  top: -90px;
}

.navbar-toggler {
  margin-left: auto;
}

.MainnNavBarButtons {
  display: flex !important;
  justify-content: center !important;
  flex-grow: 1 !important;
  font-family: "Inter" !important;
  font-weight: 700 !important;
  gap: 2rem !important;
}

.MainnNavBarButtons .nav-link {
  white-space: nowrap !important;
  padding: 0.5rem 0.75rem !important;
  position: relative;
  color: #333 !important;
  /* Darker color for better contrast */
}

.MainnNavBarButtons .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 100%;
  background-color: #0c3f78;
  transition: width 0.3s ease;
}

div#basic-navbar-nav {
  /* padding-bottom: 7px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media (max-width: 576px) {
  .MainnNavBarImage {
    min-width: 60px !important;
  }

  .MainnNavBarButtons {
    gap: 0.5rem !important;
  }

  .MainnNavBarButtons .nav-link {
    padding: 0.25rem 0.5rem !important;
  }
  div#basic-navbar-nav {
    padding-bottom: 0px !important;
  }
}

@media (max-width: 768px) {
  .MainnNavBarButtons {
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    justify-content: flex-start !important;
  }
}
.mainnnContainerNavComponent.blur-background {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(251, 250, 248, 0.5);
}

.naaaavdropdown {
}
@media (max-width: 770px) {
  .naaaavdropdown {
    font-size: 16px !important;
  }
}
.naaaavdropdown-item {
  cursor: pointer !important;
  /* color: black !important; */
  font-weight: 500 !important;
  font-size: 24px !important;
}

@media (max-width: 768px) {
  .naaaavdropdown-item {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .naaaavdropdown-item {
    font-size: 16px !important;
  }
}
.naaaavdropdown-item2 {
  cursor: pointer !important;
  color: black !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

/* media querry with text responsiveness */

@media (max-width: 768px) {
  .naaaavdropdown-item2 {
    font-size: 14px !important;
  }
}

@media (max-width: 576px) {
  .naaaavdropdown-item2 {
    font-size: 12px !important;
  }
}

/* .naaaavdropdown>.naaaavdropdownicon {
  background-color: rgba(128, 128, 128, 0.5);
  border: 1px solid transparent; 
  transition: background-color 0.3s ease; 
}

.naaaavdropdown:hover>.naaaavdropdownicon {
  background-color: black; 
  color: white; 
} */

.naaaavdropdownbox {
  color: black !important;
  max-width: 500px;
  margin: auto;
}
.naaaavdropdownbox:hover {
  color: gray !important;
  > .naaaavdropdown-item:hover {
    color: black !important;
  }
}

@media (max-width: 768px) {
  .dropdown-small-screen {
    top: 0px !important;
  }
}