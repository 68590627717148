.ReadyToTalk-header{
    font-size: 45px;
    font-weight: 500;
    line-height: 48px;
}


@media (max-width:768px) {
    .ReadyToTalk-header{
        font-size: 30px;
        font-weight: 500;
        line-height: 28px;
    }
}