/* 
#CED2D3
*/

.industries-wrapper {
  /* background-color: #1C1C1C; */
  background-color: #CED2D3;
  color: rgb(0, 0, 0);
  height: 90vh;
}

.industries-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  /* background-image: url('https://cdn.prod.website-files.com/664e075a24cd16808e4650fc/665ddb0df29ed73dfe1f6759_branded-svg_light.svg'); */
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  pointer-events: none;
}

.industries-label {
  color: #000000;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
}

.industries-title {
  font-size: 5rem;
  line-height: 1.1;
}

.industries-link {
  color: black;
  text-decoration: none;
  font-size: 1rem;
  transition: opacity 0.3s ease;
}

.industries-link:hover {
  opacity: 0.8;
}

.industries-arrow {
  font-size: 1.25rem;
}

.industries-image-wrapper {
  position: relative;
  width: 100%;
  /* height: 100%; */
  /* min-height: 600px; */
}

@media (max-width: 991px) {
  .industries-title {
    font-size: 3.5rem;
  }
}

.industriessecondComp-accordion .accordion-button {
  background: none;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1.5rem 0;
  border-bottom: 1px solid #dee2e6;
}

.industriessecondComp-accordion .accordion-button:not(.collapsed) {
  background: none;
  box-shadow: none;
}

.industriessecondComp-accordion .accordion-button:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.industriessecondComp-accordion .accordion-body {
  padding: 1.5rem 0;
}

.industriessecondComp-accordion .accordion-button::after {
  width: 1.5rem;
  height: 1.5rem;
  background-size: 1.5rem;
}


.IndustriesClientComponent-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.IndustriesClientComponent-label {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.blue-square {
  width: 12px;
  height: 12px;
  background-color: #2563EB;
}

.IndustriesClientComponent-label span {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.IndustriesClientComponent-heading {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.1;
  margin: 0;
}

.IndustriesClientComponent-description {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #4B5563;
  margin: 0;
  max-width: 90%;
}


.IndustriesClientComponentclient-logo {
  /* padding: 2rem;
  border-radius: 0.5rem; */
  width: 100%;
}

.IndustriesClientComponent-iconbox{
  border: 1px solid #E5E7EB;
  object-fit: contain;
  /* aspect-ratio: 1/1; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.IndustriesClientComponentclient-logo{
  border: 1px solid #E5E7EB;
  padding: 2rem;
  object-fit: contain;
  /* width: 50%; */
}
